<template>
    <div>
        <div v-if="$viewport.isGreaterThan('md')" class="sticky top-22 bg-ui-bg border-b border-ui-bg-accented pb-3 pt-2 z-20 -translate-y-px">
            <div class="my-container px-5">
                <UBreadcrumb :items="links" />
            </div>
        </div>
        <div class="min-h-svh">
            <slot />
        </div>
        <div
            v-if="$viewport.isLessOrEquals('md')"
            class="sticky bottom-14 bg-ui-bg border-t border-ui-border-accented py-3 translate-y-0.5 transition-[bottom] duration-300"
            :class="{ '!bottom-0': scrollDown }"
        >
            <div class="my-container pl-5 sm:pl-10">
                <UBreadcrumb :items="links" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { BreadcrumbItem } from '#ui/types';
import type { RouteLocationMatched } from 'vue-router';
const { t } = useI18n();
const { scrollDown } = useScrollDirection();

const links = ref<BreadcrumbItem[]>([]);
const router = useRouter();
const remove = router.afterEach(to => {
    if (to.meta.showBreadcrumb) buildLinks(to.matched);
});

onUnmounted(() => {
    remove();
});
// onBeforeRouteUpdate((to, from, next) => {
//   //console.log('onBeforeRouteUpdate')
//   buildLinks(to.matched)
//   next()
// })
buildLinks(router.currentRoute.value.matched);

function buildLinks(matched: RouteLocationMatched[]) {
    links.value = [];
    console.log('build links');
    matched.forEach(m => {
        if (m.meta.pageI18nName) links.value.push({ to: m.path, label: t(m.meta.pageI18nName || '') });
    });
}
</script>

<style></style>
